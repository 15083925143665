
























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway.ts'

import Modal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import QuestionListItem from '@/components/implementation/question/QuestionListItem.vue'

import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { QuestionCreateRequest } from '@/requests/implementations/questions/QuestionCreateRequest'
import { QuestionResource } from '@/models/implementations/questions/QuestionResource'

@Component({
  components: {
    Modal,
    TextInput,
    SmallLoader,
    QuestionListItem,
  },
  mixins: [clickaway],
})
export default class ImplementationQuestions extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  private createQuestionForm: ModalForm<QuestionCreateRequest> = {
    isOpen: false,
    isSubmitting: false,
    form: new QuestionCreateRequest(),
    errors: {}
  }

  private enableQuestionsMenu: ModalForm = {
    isOpen: false,
    isSubmitting: false,
  }

  private get questionsStatus(): string {
    return this.implementation.questions[0]?.status ?? 'draft'
  }

  private get implementationIsUnpublished(): boolean {
    return this.implementation.status === 'created'
  }

  private get canEnableQuestions(): boolean {
    return this.implementation.questions.length > 0 && this.implementationIsUnpublished
  }

  private get canDisableQuestions(): boolean {
    return !this.implementationIsUnpublished
  }

  private closeCreateQuestionModal() {
    this.createQuestionForm.isOpen = false
    this.createQuestionForm.form = new QuestionCreateRequest()
  }

  private openCreateQuestionModal() {
    this.createQuestionForm.isOpen = true
    this.createQuestionForm.form = new QuestionCreateRequest()
  }

  private async createQuestion(): Promise<void> {
    if (!this.createQuestionForm.form?.isValid) return

    this.createQuestionForm.isSubmitting = true
    try {
      this.implementation.createQuestion(this.createQuestionForm.form)
      this.closeCreateQuestionModal()
    } catch (e) {
      if (e.errors) {
        this.createQuestionForm.errors = e.errors
      }
    } finally {
      this.createQuestionForm.isSubmitting = false
    }
  }

  private get canCreateQuestion(): boolean {
    return this.implementation.canPerformAction('can_add_question')
  }

  private async enableQuestions(): Promise<void> {
    if (!this.canEnableQuestions) return

    this.enableQuestionsMenu.isSubmitting = true
    try {
      this.implementation.enableQuestions()
      this.closeEnableQuestionsForm()
    } finally {
      this.enableQuestionsMenu.isSubmitting = false
    }
  }

  private async disableQuestions(): Promise<void> {
    if (!this.canDisableQuestions) return

    this.enableQuestionsMenu.isSubmitting = true
    try {
      this.implementation.disableQuestions()
      this.closeEnableQuestionsForm()
    } finally {
      this.enableQuestionsMenu.isSubmitting = false
    }
  }

  private closeEnableQuestionsForm(): void {
    this.enableQuestionsMenu.isOpen = false
  }

  private toggleEnableQuestionsForm(): void {
    this.enableQuestionsMenu.isOpen = !this.enableQuestionsMenu.isOpen
  }

  private deleteQuestion(question: QuestionResource): void {
    this.implementation.deleteQuestion(question)
  }

}
